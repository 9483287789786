<script>
  import Button, { Label } from "@smui/button";
  import Dialog, { Actions, Content, Title } from "@smui/dialog";
  import Select, { Option } from "@smui/select";
  import { HTTPError } from "ky";
  import { _, locale } from "svelte-i18n";

  import backendApi from "~/libs/backendApi";
  import { NO_PICKUP_TIMEFRAME } from "~/libs/commonTypes";
  import { formatDate } from "~/libs/dateUtils";
  import loadingProgress from "~/libs/loadingProgress";
  import { toast } from "~/libs/toast";

  /** @type {string} */
  export let trackingNumber;

  /** @type {import("~/libs/commonTypes").TrackingResult} */
  export let result;

  /**
   * 再配達希望日時の表示を切り替える関数
   * @type {Function}
   */
  export let configureLocaleSpecificFormat;

  /**
   * 選択肢として表示する日数
   * @type {number}
   */
  const DATE_NUM = 7;

  /**
   * 再配達の時間帯作成で用いる選択肢
   * @type {Array<string>}
   */
  const CHOICES_OF_TIME = [
    "0900",
    "1000",
    "1100",
    "1200",
    "1300",
    "1400",
    "1500",
    "1600",
    "1700",
    "1800",
  ];

  /**
   * モーダルの開閉状況
   * @type {boolean}
   */
  let open;

  /**
   * 選択肢として表示する日付のリスト
   * @type {Array<Date>}
   */
  let dateList = [];
  for (let i = 0; i < DATE_NUM; i++) {
    let date = new Date();
    date.setDate(date.getDate() + i);

    dateList[i] = date;
  }

  /**
   * 変更前の受け取り可能時間帯リスト
   * @type {Array<string>}
   */
  let currentAvailablePickupTime = [];

  /**
   * 変更後の受け取り可能時間帯リスト
   * @type {Array<string>} */
  let newAvailablePickupTime = [];

  /**
   * 未入力の項目がある場合にtrue
   * @type {boolean}
   */
  let notAllEnteredCheck = false;

  /** @type {string} 荷受人氏名 */
  let name = sessionStorage.getItem("identificationName") ?? "";
  /** @type {string} 荷受人郵便番号 */
  let postcode = sessionStorage.getItem("identificationPostcode") ?? "";
  /** @type {string} 荷受人電話番号 */
  let tel = sessionStorage.getItem("identificationTel") ?? "";

  /**
   * $localeが更新に伴い実行するリアクティブ処理
   */
  $: {
    onChangeLocale(
      // @ts-ignore
      $locale,
    );
  }

  /**
   * $localeが更新された場合に呼び出されるリアクティブハンドラー
   */
  function onChangeLocale() {
    // selectで選択値の表示切替のため、選択値を一度リセット。
    // これにより言語切り替え後のダイアログオープン時に値が更新され、選択されたoptionが変更されることで選択値の表示が切り替わる
    newAvailablePickupTime = [];
  }

  /**
   * ダイアログを開く。
   */
  export function openDialog() {
    if (result.specifiedPickupDatetime?.availablePickupDatetime) {
      for (let i = 0; i < DATE_NUM; i++) {
        result.specifiedPickupDatetime.availablePickupDatetime.forEach(
          (datetime) => {
            if (
              new Date(datetime.date).toDateString() ===
              dateList[i].toDateString()
            ) {
              currentAvailablePickupTime[i] = datetime.timeFrame;
              newAvailablePickupTime[i] = datetime.timeFrame;
            }
          },
        );
      }
    } else {
      for (let i = 0; i < DATE_NUM; i++) {
        currentAvailablePickupTime[i] = "-1";
        newAvailablePickupTime[i] = "-1";
      }
    }
    open = true;
  }
  function closeDialog() {
    notAllEnteredCheck = false;
    open = false;
  }

  /**
   * 配送希望時間をフォーマットする
   * @param {string} timeFrame 数字4桁（開始時間2桁、終了時間2桁）
   * @param {import("svelte-i18n").locale} locale svelte-i18nのlocale
   * @returns {string} 時間指定の表示
   */
  function formatTimeFrame(timeFrame, locale) {
    let startTime = Number(timeFrame.substring(0, 2));

    let str = "";

    if (locale === "ja") {
      if (startTime === 0) {
        str = "受け取り不可";
      } else {
        str = startTime + "時 以降";
      }
    } else {
      let startTimeZone = "AM";

      if (startTime > 12) {
        startTime = startTime - 12;
        startTimeZone = "PM";
      }

      if (startTime === 0) {
        str = "Can't receive";
      } else {
        str = "after " + startTime + ":00 " + startTimeZone;
      }
    }

    return str;
  }

  /**
   * 指定された日付、および時間帯の開始時刻が現時刻より後かを判定する
   * @param {Date} date
   * @param {string} timeFrame 数字4桁（開始時間2桁、終了時間2桁）
   * @returns {boolean} 指定された日付、および時間帯が現時刻より後であればtrue、ただし、現時刻を含む時間帯もtrueとする
   */
  function isAfterNow(date, timeFrame) {
    const startTime = Number(timeFrame.substring(0, 2)) + 1;
    let formatedDate = date;
    formatedDate.setHours(startTime);

    return formatedDate > new Date();
  }

  /**
   * 再配達の受け取り可能時間帯を更新する
   */
  function updateAvailablePickupDateTime() {
    notAllEnteredCheck = false;
    newAvailablePickupTime.forEach((element) => {
      if (element === "-1") {
        notAllEnteredCheck = true;
      }
    });
    if (notAllEnteredCheck) {
      // 未選択がある場合はモーダルを閉じない
      this.setAttribute("data-mdc-dialog-action", "");
    } else {
      this.setAttribute("data-mdc-dialog-action", "close");

      let changeCheck = false;
      for (let i = 0; i < DATE_NUM; i++) {
        if (currentAvailablePickupTime[i] !== newAvailablePickupTime[i]) {
          changeCheck = true;
        }
      }

      if (changeCheck) {
        // 変更がある場合
        /** @type {Array<import("~/libs/commonTypes").DateAndTimeFrame>} */
        let newAvailablePickupDatetime = new Array(DATE_NUM);
        for (let i = 0; i < DATE_NUM; i++) {
          newAvailablePickupDatetime[i] = {
            date: formatDate(dateList[i], "yyyy-MM-dd", $locale),
            timeFrame: newAvailablePickupTime[i],
          };
        }
        /** @type {import("~/libs/commonTypes").SpecifiedPickupDatetime} */
        const newSpecifiedPickupDatetime = {
          desiredRedeliveryDatetime: { date: "", timeFrame: "" },
          availablePickupDatetime: newAvailablePickupDatetime,
        };
        /** @type {import("~/libs/backendApi").ReceiverIdentification} */
        const receiverIdentification =
          name && postcode && tel
            ? {
                name,
                postcode,
                tel,
              }
            : null;

        loadingProgress.wrapAsync(async () => {
          try {
            await backendApi.updateAvailablePickupDatetime(
              trackingNumber,
              newAvailablePickupDatetime,
              receiverIdentification,
            );
            result.specifiedPickupDatetime = newSpecifiedPickupDatetime;
            delete result.redeliveryContext.adjustedRedeliveryDatetime;
            configureLocaleSpecificFormat();
            toast.info(
              $_("pages.Tracking.SetAvailablePickupDatetimeDialog.completion"),
            );
            closeDialog();
          } catch (error) {
            showErrorToast(error);
          }
        })();
      } else {
        closeDialog();
      }
    }
  }

  /**
   * エラーメッセージをトーストで表示する。
   * @param {Error} error Errorオブジェクト
   */
  function showErrorToast(error) {
    if (error["errorResponse"]?.title === "delivery completed.") {
      toast.error($_("errors.deliveredPackage"));
    } else if (
      error instanceof HTTPError &&
      error.response?.status >= 400 &&
      error.response?.status < 500
    ) {
      console.error(error);
      toast.error($_("errors.invalidAvailablePickupDatetime"));
    } else {
      console.error(error);
      toast.error($_("errors.failedAvailablePickupDatetime"));
    }
  }
</script>

<div class="setAvailablePickupDateTimeDialog">
  <Dialog
    bind:open
    scrimClickAction=""
    escapeKeyAction=""
    aria-labelledby="set-available-pickup-datetime-dialog-title"
    aria-describedby="set-available-pickup-datetime-dialog-content"
    style="margin-top: 50px; max-height: 90%"
  >
    <Title id="set-available-pickup-datetime-dialog-title"
      >{$_("pages.Tracking.SetAvailablePickupDatetimeDialog.title")}</Title
    >
    <Content id="set-available-pickup-datetime-dialog-content">
      <p>
        {$_("pages.Tracking.SetAvailablePickupDatetimeDialog.description1")}
      </p>
      <p>
        {$_("pages.Tracking.SetAvailablePickupDatetimeDialog.description2")}
      </p>
      <div class="selectTimeArea">
        {#each dateList as date, i}
          <div class="dateData">
            <div class="date">
              {#if new Date(date).toDateString() === new Date().toDateString()}{$_(
                  "pages.Tracking.SetDesiredRedeliveryDatetimeDialog.today",
                )}{/if}{formatDate(
                date,
                $_("config.defaultDateFormat"),
                $locale,
              )}
            </div>
            <Select variant="outlined" bind:value={newAvailablePickupTime[i]}>
              <Option value="-1" hidden
                >{$_(
                  "pages.Tracking.SetAvailablePickupDatetimeDialog.select",
                )}</Option
              >
              <Option value={NO_PICKUP_TIMEFRAME}
                >{formatTimeFrame(NO_PICKUP_TIMEFRAME, $locale)}</Option
              >
              {#each CHOICES_OF_TIME as choice}
                {#if isAfterNow(date, choice)}
                  <Option value={choice}
                    >{formatTimeFrame(choice, $locale)}</Option
                  >
                {/if}
              {/each}
            </Select>
          </div>
        {/each}
      </div>
      {#if notAllEnteredCheck}
        <p class="notEnteredCaution">
          {$_(
            "pages.Tracking.SetAvailablePickupDatetimeDialog.notEnteredCaution",
          )}
        </p>
      {/if}
    </Content>
    <Actions>
      <Button on:click={closeDialog}>
        <Label
          >{$_(
            "pages.Tracking.SetAvailablePickupDatetimeDialog.cancelButtonLabel",
          )}</Label
        >
      </Button>
      <Button on:click={updateAvailablePickupDateTime}>
        <Label
          >{$_(
            "pages.Tracking.SetAvailablePickupDatetimeDialog.updateButtonLabel",
          )}</Label
        >
      </Button>
    </Actions>
  </Dialog>
</div>

<style lang="scss">
  .setAvailablePickupDateTimeDialog {
    :global(.mdc-dialog__content) {
      overflow: visible;
    }
  }
  p {
    color: #000;
    margin-bottom: 5px;
  }
  .selectTimeArea {
    box-sizing: border-box;
    color: #000;
    font-size: 14px;
    margin-top: 20px;

    :global(
        .mdc-select .mdc-select__menu .mdc-deprecated-list-item[hidden="true"]
      ) {
      display: none;
    }
    :global(.mdc-select .mdc-select__menu.mdc-menu-surface) {
      max-height: 250px !important;
    }
  }
  .selectTimeArea .dateData {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  .selectTimeArea .date {
    background-color: #018786;
    color: #fff;
    margin-right: 20px;
    padding: 15px 8px;
    text-align: center;
    width: 100px;
  }
  .notEnteredCaution {
    color: #f00;
    margin-top: 20px;
  }
</style>
